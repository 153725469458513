import React from "react"
import Layout from "@components/layout"

// 言語用Jsonファイル
import langEn from "@content/locales/en/blog-archive.json";
import langJa from "@content/locales/ja/blog-archive.json";

// 共通Link用ファイル
import { LinkSetting } from "@components/link-setting.js";
import { makeArchive } from "@components/archives-components.js";
import { SeoContents } from "@components/seo-data.js" // SEO,meta情報設定

import ogpimage from "@images/ogp.png"

// ユニークの変数名
const MarketsPostArchive = (data) => {

  const postData = data.pageContext

  // // 言語設定
  const setLang = postData.lang;
  let postLang, langUrl, categUri, setNode;
  setLang === 'en'? postLang = langEn: postLang = langJa;
  setLang === 'en'? langUrl = '/': langUrl = '/' + setLang + '/';
  categUri = postData.uri;
  setNode = postData.data;
  const selectType = 'markets'

  // 構造化データ用配列
  // コレクション
  const collection = [];
  postData.data.forEach((result) => {
    collection.push({
      "@type": "Product",
      "name": result.node.title,
      "image": process.env.ORIGINURL + ogpimage,     
      "description": result.node.seo.metaDesc,
      "url": `${process.env.ORIGINURL}${langUrl}markets-analysis/${result.node.slug}/`,
      "offers": {
        "@type": "Offer",
        "price": "0",
        "priceCurrency": "USD"
      }
    })
  })
  // 作成・更新日
  const date = {
    published: "2022-10-21T18:40:00+00:00", 
    modified: new Date(postData.data[0].node.modified).toISOString()
  };

  // Seo情報設定
  const seoTag = SeoContents( 
    categUri, setLang, 
    postLang[ selectType + "-page-title" ], 
    postLang[ selectType + "-page-description"],
    postLang[ selectType + "-page-keywords"],
    date, "archive", postData.num, '', collection,
  );
  
  // // post設定
  const posts = setNode ;
  
  // 最終ページnext消去
  let leadUri = langUrl + categUri + "/" ;
  const previousPagePath = leadUri + (postData.num - 1) + "/";
  const nextPagePath = leadUri + (postData.num + 1) + "/";
  let prevIndicate, nextIndicate;
  (postData.num - 1) === 0? prevIndicate = 'hidden': prevIndicate = '';
  postData.num === postData.maxnum? nextIndicate = 'hidden': nextIndicate = '';
  
  // リンク設定
  const signPath1 = LinkSetting( setLang ).Register;

  // 一覧生成
  let archiveList = makeArchive ( posts, leadUri, postLang, selectType );
  
  return (
    <>
      {seoTag}
      <Layout isHomePage>
        <div className="FV pt-5 pb-8 bg-fixed" langType={setLang}>
          <div className="max-w-xl mx-auto px-6 md:max-w-5xl md:pt-5 pt-2 ">
            <div className=" text-left h1_filed">
              <h1 className="md:text-[60px] text-[35px] font-semibold">{postLang[selectType + "-title"]}</h1>
              <div className="text-base">
                <p>{postLang[selectType + "-txt1"]}{postLang[selectType + "-txt2"]}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-xl mx-auto pt-5 md:max-w-5xl px-6">
          <div className="text-left md:mt-10 mt-5 h-1/2  mb-10 ">
            <h2 className="tracking-wide font-semibold text-[26px] md:text-[40px]">{postLang[selectType + "-txt3"]}</h2>
          </div>
        </div>
        <ol style={{ listStyle: `none` }} className="md:max-w-5xl px-6 mx-auto">
          <div className="mx-auto md:max-w-5xl pb-4   md:flex max-w-xl md:flex-wrap  md:justify-start items-center md:max-w-5xl">
            {archiveList}
          </div>
        </ol>

        <div className="Feature pt-5 mt-10 bg-fixed">
          <div className="flex px-5 text-[11px] md:text-sm">
            <div div className={`leftPagePath text-center w-1/2`}>
              {prevIndicate !== 'hidden'? <a href={previousPagePath}>＜ {postLang["previousPage"]}</a>: '' }
            </div>
            <div className={`rightPagePath text-center w-1/2`}>
              {nextIndicate !== 'hidden'? <a href={nextPagePath}>{postLang["nextPage"]} ＞</a>: '' }
            </div>
              {/* ページ番号追加時 */}
              {/* {secondPagePath && <Link to={secondPagePath}>Second page</Link>} */}
          </div>
          <div className="py-14 py-14  md:py-10 md:py-10">
            <div className="md:mx-auto max-w-5xl flex justify-center">
              <a href={signPath1} className="cont_regi01 rounded block md:max-w-xs mx-5  text-center text-white font-bold  px-16 my-2 bg-black hover:scale-[1.05] pt-[18px] pb-[20px] duration-300">{postLang["open"]}</a>
            </div>
          </div>
        </div>
      </Layout>
      </>
  )
}
export default MarketsPostArchive